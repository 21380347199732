import React from "react";
import { Grid, Container } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";

import Piggy from "./animation/piggy";

export default function Outrolocation(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "20%",
  });

  return (
    <Container key={props.key}>
      <Grid item className="home-outro py-24">
        <Grid item md={12}>
          <motion.div
            ref={ref}
            className="svg-icon"
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Piggy />
          </motion.div>
          <ReactMarkdown
            children={props.outro}
            className="md:text-center md:w-7/12 w-full  mx-auto"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
